import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import Text from '@components/Text';
import ContentfulText from '@components/ContentfulText';
import Seo from '@components/Seo';

export default function PodcastEpisode({ data }) {
  const content = data?.contentfulPodcastEpisode;
  return (
    <Seo title={content.title}>
      <div className="grid">
        <div className="gc-4 md:gc-1 lg:gc-4">
          <GatsbyImage
            image={getImage(content?.featuredImage?.gatsbyImageData)}
            alt={content?.title}
          />
        </div>
        <section className="gc-8 md:gc-3 lg:gc-8 block-gap-1 ">
          <h2>{content.title}</h2>
          <PodcastEmbed content={content} />
          <Text>
            <ContentfulText text={content?.description} />
          </Text>
        </section>
      </div>
    </Seo>
  );
}

function PodcastEmbed({ content }) {
  const embed = content?.podcastEmbed?.podcastEmbed;
  const link = content?.podcastLink;
  if (embed)
    return (
      <section
        className="iframe-wrapper podcast"
        dangerouslySetInnerHTML={{ __html: embed }}
      />
    );

  if (link)
    return (
      <section className="iframe-wrapper video">
        <iframe src={link} title={content.title} />
      </section>
    );

  return null;
}

export const query = graphql`
  query PodcastEpisodeQuery($id: String!) {
    contentfulPodcastEpisode(id: { eq: $id }) {
      id
      featuredImage {
        gatsbyImageData(width: 640, aspectRatio: 1)
      }
      title
      description {
        raw
      }
      podcastEmbed {
        podcastEmbed
      }
    }
  }
`;
