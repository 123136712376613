import React from 'react';
import { Helmet } from 'react-helmet';

export default function Seo({ children, title }) {
  return (
    <>
      <Helmet>
        <title>Selected Wisdom | {title}</title>
      </Helmet>
      {children}
    </>
  );
}
